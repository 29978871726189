/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;

}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

input, textarea {
  font-family: $base-font-family;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}


/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}


/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}


/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}


/**
 * Headings
 */
h1, h2 {
  font-family: $display-font-family;
  font-weight: 800;
  color: $color-main;
  letter-spacing: 0.5px;
  margin: 0.67em 0;
}
h3, h4, h5, h6 {
  font-family: $display-font-family;
  font-weight: 700;
  color: $color-main;
  letter-spacing: 0.5px;
  margin: 0.33em 0;
}
h1 {font-size: 2em;}
h2 {font-size: 1.6em;}
h3 {font-size: 1.4em;}
h4 {font-size: 1.2em;}
h5 {font-size: 1.1em;}
h6 {font-size: 1em;}


/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}


/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}


/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
  max-width:         calc(#{$content-width} - (#{$spacing-unit}));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
  @extend %clearfix;

  @media screen and (min-width: $on-large) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
  }
  @media (max-width: 450px) {
    padding-left: 0;
    padding-right: 0;
  }
}


/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: left;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th, td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}

header.site-header {
  border-top: none;
  padding: 2px 30px;
  .site-title {
    height: 60px;
    img,
    svg {
      height: 100px;
      display: block;
      @media (max-width: 600px) {
        height: 80px;
      }
      transition: 0.5s height ease;
      .is-scrolled & {
        height: 60px;
      }
    }
    @media (max-width: 350px) {
      height: 54px;
      img,
      svg {
        height: 54px;
      }
    }
  }
  svg .drain-and-sewer-svg-text {
    transition: 0.3s fill ease;
    fill: #fff;
    .is-scrolled & {
      fill: $color-secondary;
    }
  }
  background: #fff0;
  transition: 0.3s background ease, 0.3s box-shadow ease;
  .is-scrolled & {
    background: #fff;
    box-shadow: 0 3px 3px -3px rgba(0,0,0,0.3);
    color: #000;
  }
}


.footer-contact {
  // background: $color-main;
  background: $color-main;
  padding: 20px 0;
  .wrapper > span {
    margin: 20px 0;
    padding: 0 10px;
    color: #fff;
    display: block;
  }
  form {
    margin: 20px 0;
    padding: 0 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: wrap;
  }
  input, textarea, button {
    flex: 1;
    // border: 2px solid grey;
    border: none;
    border-radius: 2px;
    line-height: 2;
    font-size: 16px;
    vertical-align: middle;
    padding-left: 8px;
    margin-right: 6px;
  }
  .contact-submit {
    flex: 0.5;
    background: $color-main;
    color: white;
    border: 2px solid white;
    cursor: pointer;

  }
  .contact-email {
    margin-right: 0;
  }
  @media (max-width: 610px) {
    input {
      margin: 6px 0 0;
      flex: 1 0 100%;
    }
  }
  @media (max-width: 650px), (min-width: 768px) and (max-width: 1350px) {
    .home-form-outer & input {
      margin: 6px 0 0;
      flex: 1 0 100%;
    }
  }
  textarea {
    resize: none;
    flex: 1 0 100%;
    margin: 6px 0;
  }
  .form-response {
    flex: 1 0 100%;
    text-align: center;
    color: #fff;
    font-size: 1.25em;
    font-weight: 300;
    &.success {
      background: darkgreen;
      background: linear-gradient(to top, darkgreen, forestgreen);
      margin: 5px 0;
    }
    &.error {
      background: darkred;
      margin: 5px;
    }
  }
}

.align-left,
.align-middle,
.align-right {
  margin: 0 auto;
  display: block;
  max-width: 600px;
  width: 80%;
  padding-bottom: 10px;
  clear: both;
}
@media (min-width: 600px)  {
  .align-right {
    float: right;
    width: 50%;
    max-width: 600px;
    margin-left: 20px;
  }
  .align-left {
    float: left;
    width: 50%;
    max-width: 600px;
    margin-right: 20px;
  }
  .align-small {
    width: 33%;
    max-width: 300px;
  }
}

.dark-section {
  background: #333;
  color: #fff;
  a { color: $color-highlight; }
  h1,h2,h3 { color: $color-highlight; }
}

.highlight-section {
  background: darken($color-main,10%);
  color: #fff;
  a { color: $color-highlight; }
  h1,h2,h3 { color: $color-highlight; }
  a.btn.btn-secondary {
    background: white;
    color: $color-main;
    border-color: $color-main;
  }
}

.home-reviews-section {
  clear: both;
  padding: 30px 5px;
  .home-reviews {
    display: flex;
    flex-wrap: wrap;
    .home-review {
      @media (min-width: 768px) {
        flex: 1 0 40%;
      }
      border: 1px solid orange;
      margin: 5px;
      padding: 10px;
      box-shadow: 0 1px 5px rgba(0,0,0,0.2);
      &:first-line {
        font-weight: 600;
      }
      text-align: center;
      .home-review-stars {
        svg {
          width: 24px;
          height: 24px;
          path {
            fill: orange;
          }
        }
      }
    }
  }
}

.home-locations-section {
  background: #222;
  color: white;

  clear: left;
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;
  h2 {
    font-size: 2em;
  }
}
.home-locations {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 5px;
  .home-location {
    flex: 1 0 180px;
    padding: 10px;
    .home-location-more-info {
      opacity: 0;
      max-height: 0;
      transition: 0.3s ease opacity, 0.3s ease max-height;
      pointer-events: none;
    }
    background: none;
    transition: 0.5s ease background;
    .fa-angle-down {
      transition: 0.4s transform ease;
    }
    &.show-info {
      .fa-angle-down {transform: rotate(180deg);}
      background: #333;
      .home-location-more-info {
        opacity: 1;
        max-height: 200px;
      }
    }
    svg {
      width: 30px;
    }
    .location-name {
      cursor: pointer;
      svg {
        color: $grey-color;
      }
    }
    h5 svg {
      font-size: 1.2em;
      color: $color-main;
    }
    h5 a {
      color: darken($color-main, 10%);
    }
  }
}


$nav-height: 64px;
@media (min-width: 600px) and (max-width: 1188px) {
  $nav-height: 120px;
}
.home-hero-content-middle {
  padding-top: $nav-height;

  font-family: $display-font-family;
}

.post, .page {
  padding-top: $nav-height;
  .page-hero, img.page-banner {
    margin-top: -$nav-height;
    .page-hero-content-middle {
      padding-top: $nav-height;
    }
  }
}

img.page-banner.page-banner-top-spacing {
  background: #0b233f; 
  padding-top: 64px;
  @media (min-width: 768px){
    padding-top: 130px;
  }
}

 
.split-section {
  display: flex;
  flex-wrap: wrap;
  margin: 30px auto;
}
.split-section {
  .split-section-text {
    padding: 20px;
    margin: 20px 0;
    // background: $color-secondary;
    font-size: 1.2em;
  }
  .split-section-img {
    background: #444;
    // border-radius: 20px;
    min-height: 400px;
    background-size: cover;
    background-position: center;
  }
  .split-section-img,
  .split-section-text {
    flex: 1 0 50%;
    max-width: 50%;
  }
  @media (max-width: 767px) {
    .split-section-img,
    .split-section-text {
      flex: 1 0 100%;
      max-width: 100%;
    }
    &.split-section-img-right {
      .split-section-img {
        order: -1;
      }
    }
  
  }
}
.above-header-text {
  color: #fff;
  font-size: 1.3em;
  font-weight: 300;
  .is-scrolled & {
    color: #000;
  }
  @media (max-width: 850px) {
    display: none;
  }
}
.above-header {
  position: relative;
  top: -3px;
  left: -500px;
  right: -500px;
  padding: 3px 500px;
  width: 100%;
  width: calc(100% + 1000px);
  background: #0005;
  display: block;
  line-height: 40px;
  transition: 0.3s margin-top ease;
  margin-top: 0;
  .is-scrolled & {
    margin-top: -47px;
  }
}
.above-header .header-phone {
  clear: right;
  float: right;
  color: #fff;
  svg {color: $color-highlight;}
  text-shadow: 0 1px 3px #0003, 0 0px 1px #0003; 
  font-size: 1.5em;
  transition: 0.5s font-size ease;
  .header-phone-text {
    font-weight: 300;
    font-size: 0.667em;
    line-height: 1;
    vertical-align: middle;
    padding-right: 10px;
  }
  .header-phone-number {
    &, &:visited {
      color: #fff;
      color: $color-highlight;
    }
  }
  .is-scrolled & {
    font-size: 1.5em;
    color: $color-secondary;
    svg {color: $color-secondary;}
    text-shadow: none; 
  }
  // background: #fff;
  // background: linear-gradient(110deg, #1756a9 25.5%, #2271cb 26%);
  // background: linear-gradient(to top, #1756a9, #2271cb);
  // color: white;
  padding: 10px;
  margin-left: 10px;
  font-weight: 700;
  // line-height: 50px;
  line-height: 1;
  margin-top: 0px;
  border-radius: 2px;
  // @media (max-width: 767px) {
  //   padding: 0 10px;
  //   font-size: 1.5em;
  //   text-shadow: none;
  //   margin-right: 40px;
  // }
  font-family: $display-font-family;
  i, .fas {
    width: 1em;
    display: inline-block;
    color: $color-main;
    fill: $color-main;
  }
  @media (max-width: 850px) {
    display: block;
    text-align: center;
    float: none;
    padding: 8px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .site-nav .trigger {
    margin-top: 6px;
    line-height: 1;
    .has-sub-pages {
      display: inline-block;
      &:hover, &:focus {
        > .page-link {
          background: darken($color-highlight, 10%);
        }
      }
    }
    .page-link.page-link {
      // background: $color-main;
      // border-radius: 80px;
      transition: 0.2s background ease;
      &:hover, &:focus {
        background: darken($color-highlight, 10%);
      }
      color: #fff;
      padding: 12px 24px;
      display: inline-block;
      margin-right: 0;
      font-weight: 600;
      letter-spacing: 0.5px;
      &.page-link-current {
        background: #eee;
        color: #111;
        &:hover, &:focus {
          color: #eee;
          background: #111;
        }
      }
      .is-scrolled & {
        color: #111;
      }
    }
    .page-link.page-link-highlight {
      background: $color-main;
      color: #eee;
      &:hover, &:focus {
        color: #fff;
        background: darken($color-main, 10%);
      }
      .is-scrolled & {
        color: #fff;
      }
    }
    .header-sub-menu {
      background: darken($color-main, 5%);
      padding-left: 0;
      // border-radius: 10px;
      .page-link-current {
        color: #111;
      }
      .page-sub-link {

        background: none;
        color: #fff;
        svg {transition: 0.3s margin ease;}
        &:hover svg {
          margin-right: 10px;
        }
        &:hover, &:focus {
          background: #0003;
        }
        &:first-child {
          // border-radius: 8px 8px 0 0;
        }
        &:last-child {
          // border-radius: 0 0 8px 8px;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  img.alignright {float: right;}
  img.alignleft {float: left;}
}
.social-media-list {
  font-size: 40px;
  .fa-facebook {
    color: #3b5998;
  }
  .fa-instagram {
    color: #C13584;
  }
  .fa-pinterest {
    color: #C92228; 
  }
  .fa-twitter {
    color: #1da1f2;
  }
  .fa-youtube {
    color: #c4302b;
  }
}

.footer-nav {
  background: darken($color-main, 10%);
  position: relative;
  z-index: 1;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  a {
    max-width: 100%;
    flex: 1 0 auto;
    padding: 4px 12px;
    margin: 2px;
    color: #fff;
    font-weight: 500;
    .fa-arrow-circle-right {
      transform: scale(0.8);
    }
  }
  
}
.home-location-nav,
.footer-location-nav {
  background: darken($color-main, 20%);
  position: relative;
  z-index: 1;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
  }
  h4 {
    font-size: 1rem;
    flex: 0 0 auto;
    padding: 2px 12px;
    margin: 3px;
    font-weight: 500;
    color: #fff;
  }
  .footer-location {
    flex: 0 0 auto;
    padding: 2px 12px;
    margin: 3px 6px;
    color: #eee;
    font-weight: 300;
    border-radius: 2px;
    .fa-angle-down {
      transform: scale(0.8);
    }
    .footer-location-links {
      display: block;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s opacity ease;
      top: 100%;
      left: 0;
      min-width: 100%;
      width: max-content;
      font-weight: 500;
      z-index: 9;
      a {
        display: block;
        background: $color-main;
        margin: 0;
        color: white;
        padding: 6px 10px;
        border: 1px solid $color-main;
        border-bottom: 0;
        font-size: 0.9em;
      }
      border-bottom: 1px solid $color-main;
    }
  }
  .footer-location-has-pages {
    transition: 0.3s ease border-radius, 0.3s ease background, 0.3s ease width;
    position: relative;
    cursor: pointer;
    &:hover,
    .show-footer-location-links {
      border-radius: 0 0 2px 2px;
      background: $color-main;
      .footer-location-links {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }  
}
.home-location-nav .wrapper {
  justify-content: center;
}

.copyright-bar {
  position: relative;
  z-index: 9;
  background: darken($color-main, 40%);
  color: #fff;
  padding: 5px 40px;
  text-align: center;
  a {
    color: #ccc!important;
    text-decoration: underline;
  }
}

.header-sub-menu {
  background: #fafafa;
  padding-left: 10px;
  border-left: 4px solid lightgrey;
  line-height: 40px;

  @media (max-width: 767px) {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s max-height ease, 0.3s opacity ease;
  }
  @media (min-width: 768px) {
    display: none;
  }
}
.has-sub-pages {
  position: relative;
  display: inline;
}

.no-touchevents .has-sub-pages:hover .header-sub-menu,
.touchevents .has-sub-pages .header-sub-menu.show-sub-menu {
  @media (max-width: 767px) {
    opacity: 1;
    max-height: 350px;
    a {
      display: block;
      text-align: left;
      padding: 5px 10px;
    }
  }
}

@media (min-width: 768px) {
  .no-touchevents .has-sub-pages:hover .header-sub-menu,
  .touchevents .has-sub-pages .header-sub-menu.show-sub-menu {
    // background: white;
    display: block;
    position: absolute;
    top: 100%;
    border: 2px solid darken($color-main, 10%);
    width: 250px;
    width: max-content;
    right: 50%;
    transform: translate(50%, 0);
    a {
      display: block;
      text-align: left;
      padding: 5px 10px;
      &:not(.page-link-current) {
        transition: 0.3s padding ease;
        &:hover {
          padding-left: 20px;
        }
      }
    }
  }
}

.social-media-list img {
  height: 40px;
}

.page-coupons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #eee;
  padding: 20px 0;
  position: relative;
  z-index: 1;
  .coupon {
    flex: 1 0 20%;
    @media (max-width: 700px) {
      flex: 1 0 40%;
    }
    // width: 225px;
    max-width: 300px;
    margin: 10px;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .click-to-print {
    margin-bottom: 0;
  }
}
.sidebar-coupon {
  max-width: 320px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.page-coupons .coupon,
.sidebar-coupon {
  transition: 0.2s transform ease;
  &:hover, &:visited, &:focus {
    transform: scale(1.05);
  }
}

.click-to-print {
  text-align: center;
  font-size: 0.9em;
}


.page-banner {
  width: 100%;
  height: auto;
}

.page-banner.page-banner-no-text {
  object-position: bottom;
}
.page-content {
  background: #eee;
  > div > .wrapper {
    background: #fff;
  }
  .page-banner {
    @media (min-width: 800px) {
      // margin: 0 -30px;
      // max-width: calc(100% + 60px);
      // width: calc(100% + 60px);
      // width: auto;
      max-height: 800px;
      object-fit: cover;
      object-position: center;

      &.page-banner-no-text {
        height: 50vh;
      }
    }
  }
}


.contact-page-form,
.sidebar-contact {
  font-size: 1.1em;
  input,
  button,
  textarea {
    font-size: 1.1em;
    padding: 8px;
    width: 90%;
    max-width: 500px;
    margin: 0.25em auto 0.75em;
    letter-spacing: 0.5px;
  }
  input[type="checkbox"] {
    width: auto;
    margin-right: 0.5em;
  }
  .contact-submit {
		font-size: 1.2em;
		letter-spacing: 1px;
		font-weight: 600;
		background: $color-main;
		color: #fff;
		display: block;
		padding: 5px 10px;
		position: relative;
		margin: 20px auto;
		// border-radius: 8px;
    background: linear-gradient(to top, darken($color-main, 5%), lighten($color-main, 5%));
    line-height: 1.5;
  }
}
@media (min-width: 768px) {
  .contact-us .post-content {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    > div, > form {
      flex: 1;
      padding: 0 10px 10px;
    }
  }
}

.sidebar-contact {
  background: $color-main;
  padding: 10px;
  .contact-submit {
    margin: 20px auto 5px;
    display: block;
  }
}

#searchInput {
  padding: 10px 15px;
  border: 1px solid grey;
  font-size: 1.5em;
}

.footer-col-wrapper {
  padding: 15px;
}
.footer-col {
  text-align: center;
}

.post-content,
.post-header {
  padding: 0 15px;
  @media (min-width: 768px) {
    font-size: 1.25em;
  }
}

.post-content video {
  max-width: 100%;
}

.youtube-iframe-wrap {
  max-width: 700px;
  margin: 2em 0;
  &.align-right,
  &.align-left {
    margin: 0 auto 1em;
  }
}
.youtube-iframe {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}



.page-hero {
  width: 100%;
  height: 67vh;
  height: calc(67vh - 60px);
  min-height: 600px;
	// max-height: 1080px;
	background-color: #2e5063;
	background-image: url('../../img/slider-1.png');

	@media (min-width: 2561px) {
		background-image: url('../../img/slider-1.png');
	}

	background-size: cover;
	position: relative;
	background-position: center;
	top: 0;
	left: 0;
  right: 0;
  @media (min-width: 451px) {
    left: -15px;
    right: -15px;
    width: calc(100% + 30px);
  }
  @media (min-width: 800px) {
    left: -30px;
    right: -30px;
    width: calc(100% + 60px);
  }

	.page-hero-content-left {
		position: absolute;
		width: 230px;
		top: 50%;
		transform: translate(0, -50%);
		font-size: 1.5em;
		left: 5px;

		.page-hero-content-button {
			font-size: 0.8em;
			background: linear-gradient(to top, darken($color-main, 5%), lighten($color-main, 5%));
		}

		@media (min-width: 600px) {
			width: 260px;
			left: 20px;
			font-size: 1.8em;
		}

		@media (min-width: 768px) {
			width: 320px;
			font-size: 2.3em;
		}

		@media (min-width: 1150px) {
			font-size: 3em;
			width: 420px;
			right: 50%;
    transform: translate(50%, 50%);
			margin-left: -360px;
		}
	}

	.page-hero-content-middle {
		position: absolute;
		width: 95%;
		@media (min-width: 900px) {
			width: 70%;
		}
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		font-size: 2em;
    text-align: center;
    @media (min-width: 600px) {
      font-size: 2.2em;
    }
    @media (min-width: 1000px) {
      font-size: 2.5em;
    }
	}

	.page-hero-content-text {
		font-weight: 800;
		margin-bottom: 20px;
		color: white;
		letter-spacing: 2px;
		font-size: 1.5em;
	}

	.page-hero-content-button {
		font-size: 0.7em;
		letter-spacing: 1px;
		font-weight: 600;
		background: $color-main;
		color: #fff;
		display: inline-block;
		padding: 10px 20px;
		position: relative;
		margin: 20px;
		// border-radius: 8px;
    background: linear-gradient(to top, darken($color-main, 5%), lighten($color-main, 5%));
	}
	@media (max-width: 500px) {
		.page-hero-content-middle {
			margin-top: 30px;
		}
		.page-hero-content-button {
			font-size: 0.5em;
			line-height: 2;
		}
		.page-hero-content-text {
			font-size: 1.2em;
		}

	}

	.page-hero-content-buttons {
		@media (min-width: 900px) {
			display: flex;
			// margin: 0 -50%;
			justify-content: center;
		}
	}
}

.no-touchevents .page-sub-link-main {display: none!important;}


.pest-tiles {
	font-size: 0;
	text-align: center;
	.pest-tile {
		width: 10%;
		display: inline-block;
		&:hover {
			cursor: pointer;
			transform: scale(1.1);
		}
	}
	.pest-tile-inner {    
		// box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.3), 0 0 2px rgba(0,0,0,0.3);
		border: 1px solid;
		border-radius: 2px;
		font-size: 1.3rem;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		width: 95%;
		margin: 3px;
    background: #eee;
    white-space: nowrap;
		img {
			border-radius: 2px 2px 0 0;
			width: 100%;
			height: auto;
		}
	}
	@media (max-width: 1399px) {
		.pest-tile {
			width: 14%;
		}
	}
	@media (max-width: 1000px) {
		.pest-tile {
			width: 20%;
		}
	}
	@media (max-width: 767px) {
		.pest-tile {
			width: 25%;
		}
	}
	@media (max-width: 549px) {
		.pest-tile {
			width: 33.3%;
		}
	}
	@media (max-width: 374px) {
		.pest-tile {
			width: 50%;
		}
	}
}

.grecaptcha-badge {
  z-index: 9999;
}

@media (min-width: 768px) {
  .row-2-768 {
    display: flex;
    > div {
      padding: 10px;
      flex: 1 0 50%;
    }
  }
  .row-3-768 {
    display: flex;
    > div {
      padding: 10px;
      flex: 1 0 33%;
    }
  }
  .row-4-768 {
    display: flex;
    > div {
      padding: 10px;
      flex: 1 0 25%;
    }
  }
}

@media (min-width: 1000px) {
  .page-layout {
    display: flex;
    .page-layout-inner {
      flex: 1;
    }
    .page-layout-sidebar {
      width: 320px;
      max-width: 320px; // firefox needs this for some reason
      flex: 0 0 320px;
    }
  }
}

.page-layout-sidebar {

  .social-media-list {
    a {
      margin: 0;
      padding: 2px;
    }
  }
}

.sidebar-section {
  border: 3px solid $color-main;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 3px;
  h3 {
    background: $color-main;
    color: #fff;
    margin: 0;
    padding: 5px 10px;
  }
}
.sidebar-nav {
  ul {
    list-style-type: none;
    margin: 0;
    li {
      border-bottom: 1px solid #fff;
      &:last-of-type {border-bottom: 0;}
      a, span {
        background: $color-secondary;
        color: #fff;
        display: block;
        padding: 5px 10px;
      }
      &.current-page {
        span {
          // padding-left: 20px;
        }
      }
    }
  }
}


.sidebar-reviews {

	.reviewBody {
		display: block;
		margin: 10px 40px 20px;
		position: relative;
    font-weight: 200;
    // max-width: 80%;
    @media only screen and (max-width: 450px) {
      clear: both;
      width: 100%;
      width: calc(100% - 60px);
      margin: 10px 30px;
    }
	}


	.reviewDate {
		position: relative;
		margin: 10px auto 0;
		display: block;
		line-height: 1;
		padding: 0 10px;
		font-size: 1.3em;
    font-weight: 100;
    text-align: center;
	}

	.startQuote,
	.endQuote {
		opacity: 0.4;
		position: absolute;
		font-size: 2em;
	}

	.startQuote {
		i, .fas {
			transform: scale(-1, 1);
		}

		top: -40px;
		left:-45px;
	}

	.endQuote {
		right: -45px;
		text-align: right;
		bottom: -40px;
	}

	.reviewName {
		display: block;
		margin: 0 auto;
		padding: 0 10px;
		font-size: 1.1em;
		font-weight: 300;
    text-align: center;
  }
  
  .reviewStars {
    text-align: center;
    margin: 10px auto 10px;
    display: block;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: orange;
      }
    }
  }

	.reviewBody {
		padding: 0 .5em;
		font-size: 1.2em;
	}
}

hr {
  border: 2px solid #ccc;
  width: 75%;
  margin: 2em auto;
  max-width: 300px;
}

.post-content ul {
  overflow: hidden;
  padding-left: 20px;
}

a.btn {
  font-size: 0.8em;
  letter-spacing: 1px;
  font-weight: 600;
  background: $color-main;
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  position: relative;
  margin: 10px;
  // border-radius: 8px;
  background: linear-gradient(to top, darken($color-main, 5%), lighten($color-main, 5%));
  &.btn-center {
    display: block;
    margin: 10px auto;
    width: 200px;
    width: max-content;
  }
  &.btn-secondary {
    font-size: 1em;
    background: #fff;
    color: $color-highlight;
    border: 2px solid $color-highlight;
    // border-radius: 80px;
  }
  &.btn-primary {
    font-size: 1.2em;
  }
}

.cta-buttons {
  text-align: center;
}

iframe {
  max-width: 100%;
  border: 0;
}

.grecaptcha-badge {
  opacity:0 !important;
}


@media (min-width: 768px) {
    .video-library-grid {display: flex; flex-wrap: wrap;}
    .video-library-grid .youtube-iframe-wrap {
        flex: 0 0 50%;
        padding: 0.5em;
        margin: 0;
    }
}

.upsell-popup {
  opacity: 0;
  pointer-events: none;
  transition: 0.3s opacity ease;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  .upsell-popup-inner {
    top: 60%;
    transition: 0.3s top ease;
    background: darken($color-main, 35%);
    color: #fff;
    position: fixed;
    right: 50%;
    transform: translate(50%, -50%);
    padding: 20px;
    width: 95%;
    max-width: 450px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3), 0 2px 10px rgba(0,0,0,0.5);
    a {
      color: #fff;
      font-weight: 600;
    }
  }
  body.show-upsell-popup & {
    pointer-events: initial;
    opacity: 1;
    background: rgba(20,20,20,0.25);
    .upsell-popup-inner {
      top: 50%;
    }
  }
  .upsell-popup-title {
    font-weight: 600;
    font-size: 2em;
  }
  .upsell-popup-close {
    width: 1em;
    height: 1em;
    padding: 0.5em;
    box-sizing: content-box;
    border-radius: 50%;
    background: black;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
  }
  // .upsell-popup form
  form {
    margin: 20px 0;
    padding: 0 10px;
    input, button {
      // border: 2px solid grey;
      margin: 6px 0 0;
      width: 100%;
      border: none;
      border-radius: 2px;
      line-height: 2;
      font-size: 16px;
      vertical-align: middle;
      padding-left: 8px;
      margin-right: 6px;
    }
    .contact-submit {
      flex: 0.5;
      background: $color-main;
      color: white;
      border: 2px solid white;
      cursor: pointer;
    }
    .contact-email {
      margin-right: 0;
    }
    .form-response {
      text-align: center;
      font-size: 1.25em;
      font-weight: 300;
      &.success {
        background: darkgreen;
        background: linear-gradient(to top, darkgreen, forestgreen);
        margin: 5px 0;
      }
      &.error {
        background: darkred;
        margin: 5px;
      }
    }
  }
  .upsell-popup-coupon {display: none;}
  @media (min-width: 1000px) {
    .upsell-popup-inner {
      max-width: 890px;
    }
    
    .upsell-popup-coupon {
        display: block;
        max-width: 100%;
        vertical-align: middle;
        height: auto;
        float: left;
        width: 420px;
    }
    
    form,
    .upsell-popup-title,
    .upsell-popup-text {
      width: 410px;
      float: right;
    }
  }
}

.footer-reviews {
    background: darken($color-main, 35%);
    color: lighten($color-main, 35%);
    padding: 10px;
    text-align: center;
}

#formbucket_contact {
  input, textarea {
    display: block;
    padding: 10px;
    margin: 10px auto;
    width: 100%;
  }
}


.home-hero {
	width: 100%;
  height: 60vw;
  max-height: 80vh;
  min-height: 600px;
  background-color: $color-secondary;
  // background-image: url('../../img/banners/modern-house-with-sky-dark-1920.jpg');
  // background-image: url('../../img/banners/blue-and-gray-house-2560.jpg');
  background-image: url('../../img/banners/house-front-blue-dark_1920.jpg');
	&.dark-section {
		background-color: darken($color-main, 30%);
  }
  background-position: 50% 70%;

	@media (max-width: 1440px) {
    background-image: url('../../img/banners/house-front-blue-dark_1440.jpg');
	}

	@media (max-width: 1024px) {
    background-image: url('../../img/banners/house-front-blue-dark_1024.jpg');
	}

	@media (max-width: 768px) {
    background-image: url('../../img/banners/house-front-blue-dark_768.jpg');
	}

  background-size: cover;
	position: relative;
  // background-position: 95%;
  background-repeat: no-repeat;
	top: 0;
	left: 0;
	right: 0;
  .header-badges {
    img {
      height: 80px;
    }
    .bbb-badge img {
      height: 60px;
    }
    position: absolute;
    bottom: 5px;
    right: 15px;
    // background: #fff;
    // @media (max-width: 500px) {
      height: 100px;
    // }
    @media (max-width: 999px) {
      display: none;
    }
  }

	.home-hero-content-left {
		position: absolute;
		width: 230px;
		top: 50%;
		transform: translate(0, -50%);
		font-size: 1.5em;
		left: 5px;

		.home-hero-content-button {
			font-size: 0.8em;
		}

		@media (min-width: 600px) {
			width: 260px;
			left: 20px;
			font-size: 1.8em;
		}

		@media (min-width: 768px) {
			width: 320px;
			font-size: 2.3em;
		}

		@media (min-width: 1150px) {
			font-size: 3em;
			width: 420px;
			right: 50%;
transform: translate(50%, 50%);
			margin-left: -360px;
		}
	}

	.home-hero-content-middle {
		position: absolute;
    width: 95%;
    .home-hero-content-text-2 {
      font-weight: 400; 
      font-size: 0.5em;
      margin: 0px auto;
      display: block;
    }
    .home-hero-content-text-1 {
      font-weight: 700; 
      font-size: 1em;
      @media (max-width: 375px) {
        font-size: 0.6em;
      }
      margin: 0px auto;
      display: block;
      color: $color-highlight;
    }
		@media (min-width: 710px) {
      width: 650px;
      .home-hero-content-text {
        font-size: 1.666em;
        .home-hero-content-text-2 {
          font-size: 0.5em;
        }
      }
		}
		@media (min-width: 1040px) {
      width: 1000px;
      .home-hero-content-text {
        font-size: 1.7em;
        .home-hero-content-text-2 {
          font-size: 0.75em;
        }
      }
    }
    @media (max-width: 1100px) {
      .home-hero-content-text {
        font-size: 1.1em;
      }
    }
    top: 50%;
    @media (max-width: 999px) {
      top: 55%;
    }
    transform: translate(0,-50%);
    @media (min-width: 710px) {
      right: 50%;
      transform: translate(50%, -50%);
    }
		font-size: 2.5em;
		text-align: center;
	}

	.home-hero-content-text {
    margin: 0;
		font-weight: 800;
		margin-bottom: 20px;
    // text-shadow: 0 0 1px white, 0 0 3px white, 0 0 8px white, 0 0 12px white;
    color: #fff;
		.dark-section & {
			color: white;
			text-shadow: 0 0 1px $color-secondary;
		}
		letter-spacing: 2px;
		font-size: 1.5em;
	}

	.home-hero-content-button {
		font-size: 0.7em;
		letter-spacing: 1px;
		font-weight: 600;
		border: 4px solid $color-highlight;
		color: #fff;
		display: inline-block;
		padding: 5px 15px;
		position: relative;
		margin: 10px;
    // border-radius: 80px;	
    .svg-inline--fa {
      color: $color-highlight;
    }
  }
  @media (max-width: 700px) {
    min-height: 500px;
		.home-hero-content-middle {
			font-size: 2em;
		}
  }
	@media (max-width: 500px) {
    height: 40vh;
    min-height: 400px;
		.home-hero-content-middle {
			font-size: 1.8em;
			margin-top: 30px;
		}
		.home-hero-content-button {
			font-size: 0.5em;
			line-height: 2;
		}
		.home-hero-content-text {
			font-size: 1.2em;
		}
	}
	@media (max-width: 400px) {
    height: 40vh;
    min-height: 400px;
		.home-hero-content-middle {
			font-size: 1.8em;
			margin-top: 0;
      .home-hero-content-text-2 {
        font-size: 0.6em;
      }
		}
		.home-hero-content-button {
			font-size: 0.5em;
			line-height: 2;
		}
		.home-hero-content-text {
			font-size: 1.1em;
		}
	}

	.home-hero-content-buttons {
		@media (min-width: 900px) {
			display: flex;
			// margin: 0 -50%;
			justify-content: center;
		}
	}
}

.scroll-fx.fx-fade-in {
  opacity: 0;
}
.fx-fade-in {
  transition: 0.5s opacity ease;
  opacity: 1;
}
.scroll-fx.fx-fade-up {
  opacity: 0;
  transform: translate3d(0, 50%, 0);
}
.fx-fade-up {
  transition: 0.5s opacity ease, 0.5s transform ease;
  opacity: 1;
  transform: translate3d(0,0,0);
}
.fx-delay-1 {
  transition-delay: 0.2s;
}
.fx-delay-2 {
  transition-delay: 0.4s;
}
.fx-delay-3 {
  transition-delay: 0.6s;
}
.fx-delay-4 {
  transition-delay: 0.8s;
}


.reviews-wrap .btn {
  display: block;
  width: 160px;
  width: max-content;
  margin: 10px auto 20px;
}

.home-recent-posts {
	padding: 40px 10px;
	background: #eee;
	font-weight: 600;
	.post-list {
		@media (min-width: 600px) {
			display: flex;
		}
	}
}

.post-tile {
  display: block;
  transition: 0.2s transform ease;
  &:hover, &:visited, &:focus {
    transform: scale(1.05);
    text-decoration: none;
    color: #ddd;
    .post-link {
      text-decoration: underline;
    }
  }
  padding: 10px;
  margin: 10px 5px 20px;
  background: #393939;
  background: linear-gradient(-45deg, #172f49, #306296);
  // border-radius: 10px;
  box-shadow: 0 4px 5px -1px #0005, 0 0 3px #0005;
  color: #ddd;
  .post-link {
    color: #fff;
    font-size: 1em;
  }
  .post-meta {
    color: #eee;
  }
}

body.reviews .single-review {
  padding: 25px 15px;
  margin: 5px;
  border: 3px solid $color-main;
  font-size: 1.2em;
  // background: linear-gradient(-45deg, darken($color-highlight, 30%), $color-highlight);
  // box-shadow: 0 4px 5px -1px #0005, 0 0 3px #0005;
  background: #fff;
  margin-bottom: 30px;
  svg {
    color: #f9e00d;
    stroke: #7f7203;
    stroke-width: 20px;
    margin: 2px;
  }
  .reviewDate {
    font-weight: 300;
    letter-spacing: 2px;
    color: #555;
  }
  .reviewName {
    font-size: 0.8em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

}

.offer-callout-section .wrapper {
  padding: 50px;
  text-align: center;
  font-size: 1.6em;
  @media (max-width: 500px) {
    font-size: 1.25em;
  }
  letter-spacing: 1px;
  max-width: 1100px;
  strong {
    font-size: 1.8em;
  }
  .btn-secondary {
    font-size: 0.75em;
  }
}

div.center {
  text-align: center;
}

.hero-of-the-month_hero {
  margin-bottom: 30px;
  padding: 10px;
  background: #fff;
  img {
    width: 300px;
    float: left;
    padding: 0 20px 10px 0;
  }
}

.flex-768 {
  @media (min-width: 768px) {
    display: flex;
    > * {
      flex: 1;
    }
  }
}

.flex-all {
  display: flex;
  > * {
    flex: 1;
  }
}

.hero-of-the-month_hero {
  h3 {
    @media (max-width: 700px), (min-width: 1000px) and (max-width: 1200px) {
      clear: both;
    }
  }
  blockquote {
    font-size: 1.5em;
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    h2 {order: -1;}
    img {
      width: 80%;
      margin: 0 auto;
      display: block;
      float: none;
    }
  }
}

.home-3-options {
  a {
    display: block;
    width: 3em;
    margin: 30px auto 10px;
  }
}

.cert-images {
  img {
    margin: 10px;
  }
}

.layout-page .post-header h1.h1-in-banner {
  margin-top: -55px;
  color: #fff;
}
.layout-page .post-content h1.h1-in-banner {
  margin-top: -80px;
  color: #fff;
}

.home-intro-section-wrap {
  background-color: darken($color-main,40%);
  padding: 1px;
  color: #fff;
}


.theme-duotone {
  --fa-secondary-opacity: 1.0;
  --fa-primary-color: #{$color-highlight};
  --fa-secondary-color: #{desaturate($color-highlight, 50%)};
}

.flex-btns {
  justify-content: space-around;
  flex-wrap: wrap;
  .btn {
    flex: 0 0 auto;
  }
}


.sidebar-badges {
  img {
    height: 80px;
  }
  .bbb-badge img {
    height: 60px;
  }
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 1000px) {
    margin-top: -80px;
  }
}

.meet-inspector-review {
  border: 2px solid gold; 
  border-radius: 5px;
  padding: 10px; 
  margin-bottom: 1em;
  background: #fafafa;
  .fa-star {
    color: gold;
  }
}

.footer-logos {
    padding: 1em 0;
    }
    .logo-wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media(min-width: 1130px){
    max-width: 900px;
    }
    @media(min-width: 1380px){
        max-width: 1220px;
        }
    small {
    display: block;
    text-align: center;
    width: 100%;
    padding: 1em;
    }
    img {
    margin: 1em;
    }
    a {
    margin: 1em;
    img {
    margin: 0;
    }
    }
    }
    .ourReviews {
        font-size: 30px;
        font-weight: bold;
        display: block;
        text-align: center;
        padding: 1em 0.5em;
    }
    .review-logo-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 500px;
    margin: 0 auto;
    a {
    display: flex;
    max-width: 40%;
    margin: 20px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    }
    @media(min-width: 1030px){
        max-width: 1200px;
    }
    .reviewBtn {
        margin-top: 0.5em;
        display: block;
        text-align: center;
        background-color: #1160bb;
        color: #FFF;
        padding: 0.1em 0.5em 0.15em 0.5em;
        border-radius: 0.25em;
    }
    }
    .footer-review-logos {
        padding: 1em 0 3em 0;
    }